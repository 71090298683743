<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo">

        <color-logo />

        <h2 class="brand-text text-primary ml-1">
          {{ siteTitle }}
        </h2>
      </b-link>
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            {{ $t('Adventure starts here') }}
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('Register Your Account Please') }}
          </b-card-text>

          <!-- form -->
          <b-form class="auth-register-form mt-2">
            <!-- email -->
            <vx-input
              v-model="email"
              :label="$t('Email')"
              type="email"
            />
            <span
              v-if="errors.email"
              v-show="errors.email"
              class="text-danger text-sm validation_error_avatar"
            >
              {{ $t(errors.email[0]) }}
            </span>
            <vx-input
              v-model="email_confirmation"
              class="mt-2"
              :label="$t('Confirm Email')"
              type="email"
            />
            <span
              v-if="errors.email_confirmation"
              v-show="errors.email_confirmation"
              class="text-danger text-sm validation_error_avatar"
            >
              {{ $t(errors.email_confirmation[0]) }}
            </span>
            <b-button
              variant="primary"
              block
              @click="validationForm()"
            >
              {{ $t('Sign up') }}
            </b-button>
          </b-form>

          <p class="text-center mt-2">
            <span>{{ $t('Already have an account?') }}</span>
            <b-link :to="{name:'login'}">
              <span>&nbsp;{{ $t('Sign in instead') }}</span>
            </b-link>
          </p>
        </b-col>
      </b-col>
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import ColorLogo from '@/layouts/components/ColorLogo.vue'

export default {
  components: {
    ColorLogo,
  },
  data() {
    return {
      email: '',
      email_confirmation: '',
      errors: [],
      sideImg: require('@/assets/images/pages/register-v2.svg'),
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    siteTitle() {
      return this.$store.getters['app/title']
    },
  },
  methods: {
    validationForm() {
      this.$store.dispatch('auth/Register', { email: this.email, email_confirmation: this.email_confirmation }).then(() => {
        this.$router.push({ name: 'RegisterCode', params: { email: this.email } })
        this.email = ''
        this.email_confirmation = ''
        this.errors = []
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Form Submitted Check your email',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      }).catch(error => {
        if (error.response.data.errors) this.errors = error.response.data.errors
      })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
